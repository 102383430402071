import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DecomptePageModule} from '../components/decompte/decompte.module';


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        RouterModule,
        DecomptePageModule,
    ],
    exports: [
        DecomptePageModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}