import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController, ToastController} from '@ionic/angular';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { GlobalService } from 'src/app/services/global.service';
import { JdfService, clientModel } from 'src/app/services/jdf.service';
import { environment } from '../../../environments/environment';
import { TempsUpdatePage } from '../../modals/temps-update/temps-update.page';
import { TempsListePage } from '../../modals/temps-liste/temps-liste.page';
import { Clipboard } from '@capacitor/clipboard';
import { Router } from '@angular/router';

export type projetModel = {
  id: string;
  name?: string;
  temps?: string;
};

@Component({
  selector: 'app-decompte',
  templateUrl: './decompte.page.html',
  styleUrls: ['./decompte.page.scss'],
})
export class DecomptePage implements OnInit {
  public environment = environment;

  @Input() client: clientModel = null; //
  @Input() mode: 'evolution' | 'credit' = 'credit';

  public temps: any;

  public encours = null;
  public declenche = false;
  public text = '';

  public timer: any; // set interval
  public timePause;
  public ecartPause;
  public countDown; // date de fin prévue
  public startDate; // date de départ pour chrono
  public chrono; // chrono

  // public  days_txt;
  public hours_txt;
  public minutes_txt;
  public seconds_txt;
  public h_txt;
  public m_txt;
  public s_txt;
  public chrono_txt;
  public tempsrestant_txt;
  public isPaused: boolean = false;

  public decompte_doc: any;
  public decompteID: any;

  public isEvolution : boolean = false;
  public categoriesEvolution : any;
  public categorieEvolutionSelected : '';

  public imagePreview = {
    avant: '',
    apres: ''
  };

  public selectedFilesAvant: any;
  public selectedFilesApres: any;

  // projets

  public projets: projetModel[] = []; // liste des projets
  public tousLesProjets: projetModel[] = []; // liste tous les projets
  public projet: projetModel; // le projet sélectionné en cours
  public selectedProjet= 0 ; // projet du selecteur ion-select

  constructor(
    public jdfService: JdfService,
    public httpClient: HttpClient,
    public globalService: GlobalService,
    public authService: AuthentificationService,
    public modalController: ModalController,
    public alertController: AlertController,
    public toastController: ToastController,
    public router: Router,
    ) {
    this.ecartPause = 0;
  }

  ngOnInit() {
    this.recupTemps();
    this.chargeLesProjets();
    this.chargeTousLesProjetsrojets();
    this.initLive();
    this.recupCategoriesEvolution();
  }

  previewImages(event: any, previewId: string) {
    const preview = document.getElementById(previewId);
    preview.innerHTML = '';

    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const image = new Image();

        reader.onload = (e: any) => {
          image.src = e.target.result;
          if (previewId == 'avantPreview') {
            this.imagePreview.avant += image.outerHTML;
          } else {
            this.imagePreview.apres += image.outerHTML;
          }
          // preview.appendChild(image);
        };

        reader.readAsDataURL(files[i]);
      }
    }
  }

  clearFileSelection(file) {
    let input: any;
    
    if (file == 'avantPreview') {
      this.imagePreview.avant = '';
      this.selectedFilesAvant = '';
      input = document.getElementById('file-upload-avant') as HTMLInputElement;
    } else {
      this.selectedFilesApres = '';
      this.imagePreview.apres = '';
      input = document.getElementById('file-upload-apres') as HTMLInputElement;
    }
    
    input.value = null;
  }
  
  chargeTousLesProjetsrojets() {
    this.httpClient.get(environment.api.api+'/DT_viewProjets_json.php?id='+this.client['id']).toPromise()
    .then( (json:any)=> {
      this.tousLesProjets= json;
      if (!environment.production) console.log('chargeTousLesProjetsrojets : ',this.projets);
    })
    .catch(error => {
      console.log(error);
    })
  }

  chargeLesProjets() {
    this.httpClient
      .get(
        environment.api.api +
          '/DT_viewTempsProjets_json.php?id=' +
          this.client['id']
      )
      .toPromise()
      .then((json: any) => {
        this.projets = json;
        console.log('chargeLesProjets: ', this.projets);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  chercheEvolution(str) {
    let regex: RegExp = /[éèêëe]volution/i;
    let match: boolean = regex.test(str);
    return match;
  }

  aucunProjet(){
    this.selectedProjet = 0;
    this.projet = undefined;
    this.text = '';
    this.isEvolution = false;
  }

  selectionneProjetSelect(event){
      this.selectedProjet = event.detail.value;
      this.tousLesProjets.forEach(element => {
        if( element.id == this.selectedProjet.toString()) {
          this.selectionneProjet(element);
        }
      });
      this.isEvolution = false;
      this.categorieEvolutionSelected = '';
  }
  selectionneProjet(prj) {
    // trouve le mois en cours
    let ladate = new Date();
    let tab_mois = new Array(
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    );
    let mois = tab_mois[ladate.getMonth()];

    let is_evolution = this.chercheEvolution(prj.name);
    this.categorieEvolutionSelected = '';

    if (!is_evolution) { 
      mois = '';
      this.isEvolution = false;
    } else {
      this.isEvolution = true;
    }

    console.log('selectionneProjet', prj);
    if (!this.projet) {
      // sélection du projet si il n'existe pas

      this.projet = prj;
      this.text = prj.name + ' : ' + mois;
    } else {
      console.log('selectionneProjet', this.projet.id, prj.id);
      if (this.projet.id != prj.id) {
        // sélection du projet
        this.projet = prj;
        this.selectedProjet = 0;
        this.text = prj.name + ' : ' + mois;
      } else {
        // dé-sélection du projet
        this.selectedProjet = 0;
        this.projet = undefined;
        this.text = '';
        this.isEvolution = false;
      }
    }
  }

  initLive() {
    // vérification si le user existe déjà sur un autre décompte avant le délenchement

    // popin suppression  des encours

    this.decompteID = this.globalService.generateUniqueFirestoreId();
    this.jdfService.decompteIDencours = this.decompteID;
    this.decompte_doc = {
      client: this.client,
      chrono: '00:00:00',
      restant: this.client.temps,
      play: this.encours,
      user: this.authService.user,
      date_rec: new Date(),
      decompteID: this.decompteID,
    };
    this.authService.db
      .collection('decompte')
      .doc(this.decompteID)
      .set(this.decompte_doc);
    //.then(data=>{
    //this.decompteID = data.id;
    //})
  }

  updateLive() {
    this.authService.db
      .collection('decompte')
      .doc(this.decompteID)
      .update({
        chrono: this.chrono_txt,
        restant: this.tempsrestant_txt,
        play: this.encours,
      })
      .catch((error) => {
        this.initLive();
      });
  }

  recupTemps() {
    this.httpClient
      .get(
        environment.api.api + '/DT_viewTemps_json.php?id=' + this.client['id']
      )
      .toPromise()
      .then((json: any) => {
        this.temps = json[0].t;
        console.log('TEMPS : ', this.temps);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async modalExport(ev: any, client) {
    console.log('modalExport()');

    let inputs = [];
    inputs.push({
      label: 'Tous les projets',
      type: 'radio',
      value: 0,
    });
    if(this.projets){
      this.projets.forEach((element) => {
        inputs.push({
          label: element.name,
          type: 'radio',
          value: element.id,
        });
      });
    }

    const alert = await this.alertController.create({
      header: 'Sélectionner le projet',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
        },
        {
          text: 'Exporter',
          role: 'confirm',
        },
      ],
      inputs,
    });

    alert.onDidDismiss().then((data) => {
      console.log(data, client);
      if (data.data) {
        if (data.role == 'confirm') {
          let projets_id = data.data.values;
          let utilisateur_id = +this.client['id'];
          this.export(utilisateur_id, projets_id, 'csv', true);
        }
      }
    });
    return await alert.present();
  }
  async export(utilisateur_id, projets_id, format, forcedownload) {
    let lien = environment.api.api +'/DT_export_csv.php?utilisateur_id=' +utilisateur_id +'&projets_id=' +projets_id +'&format=' +format +'&forcedownload=' +forcedownload;
    if (forcedownload) {
      window.location.href=lien;
      console.log(lien);
    } else {
      this.httpClient
        .get(lien)
        .toPromise()
        .then(async (csv: any) => {
          console.log('export : ', csv);

          if (format === 'json') {
            csv = JSON.stringify(csv);
          }

          await Clipboard.write({
            string: csv,
          });
          this.globalService.toast('CSV copié dans le presse papier');
        }).catch(async (error) => {
          this.globalService.toast(error.message);
          console.log(error);
        });
    }
  }

  async modalTempsUpdate(ev: any, client, action) {
    const modal = await this.modalController.create({
      component: TempsUpdatePage,
      cssClass: 'temps-popover',
      componentProps: { client, action },
      swipeToClose: true,
      // event: ev,
      // translucent: true
    });
    modal.onDidDismiss().then((data) => {
      console.log(data, client);
      if (data.data) {
        this.client.temps = data.data.temps;
        this.recupTemps();
        //this.tick()
        //this.updateLive();
      }
    });
    return await modal.present();
  }
  async modalTempsView(ev: any, client) {
    const modal = await this.modalController.create({
      component: TempsListePage,
      cssClass: 'temps-popover',
      componentProps: { client },
      swipeToClose: true,
      // event: ev,
      // translucent: true
    });
    /*modal.onDidDismiss().then((data) => {
      console.log(data, client);
      if (data.data) {
        this.client.temps = data.data.temps;
        this.recupTemps();
        //this.tick()
        //this.updateLive();
      }
    });*/
    return await modal.present();
  }

  tick() {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    let now = new Date().getTime() - this.ecartPause;
    // console.log(this.countDown, now );
    let distance = this.countDown - now; // temps avant le décompte

    let diff = now - this.startDate; // temps depuis maintenant;

    // décompte
    // this.days_txt = Math.floor(distance / (day));
    this.hours_txt = Math.floor((distance % day) / hour);
    this.minutes_txt = Math.floor((distance % hour) / minute);
    this.seconds_txt = Math.floor((distance % minute) / second);

    if (this.hours_txt < 10) this.hours_txt = '0' + this.hours_txt;
    if (this.minutes_txt < 10) this.minutes_txt = '0' + this.minutes_txt;
    if (this.seconds_txt < 10) this.seconds_txt = '0' + this.seconds_txt;
    this.tempsrestant_txt =
      this.hours_txt + ':' + this.minutes_txt + ':' + this.seconds_txt;

    // chrono
    // this.days_txt = Math.floor(diff / (day));
    this.h_txt = Math.floor((diff % day) / hour);
    this.m_txt = Math.floor((diff % hour) / minute);
    this.s_txt = Math.floor((diff % minute) / second);

    if (this.h_txt < 10) this.h_txt = '0' + this.h_txt;
    if (this.m_txt < 10) this.m_txt = '0' + this.m_txt;
    if (this.s_txt < 10) this.s_txt = '0' + this.s_txt;
    this.chrono_txt = this.h_txt + ':' + this.m_txt + ':' + this.s_txt;

    this.updateLive();
  }

  play() {
    console.log('play()');
    this.encours = true;
    this.declenche = true;

    // let birthday = "Sep 30, 2021 00:00:00";
    // this.countDown = new Date(birthday).getTime();

    if (!this.isPaused) {
      /// si départ sans pause
      const ajout = this.temps.split(':');
      console.log(ajout);
      this.chrono = new Date();
      this.chrono.setHours(this.chrono.getHours() + Number(ajout[0]));
      this.chrono.setMinutes(this.chrono.getMinutes() + Number(ajout[1]));
      this.chrono.setSeconds(this.chrono.getSeconds() + Number(ajout[2]));

      this.countDown = this.chrono.getTime();
      this.startDate = new Date().getTime();
      this.isPaused = false;
    } else {
      // calcul de la diférence de temps de pause ou ajout du temps
      this.ecartPause += new Date().getTime() - this.timePause;
      this.isPaused = false;
    }

    if (!this.isPaused) {
      this.tick();
    }
    this.timer = setInterval(() => {
      if (!this.isPaused) {
        this.tick();
      }
    }, 1000);
  }

  pause() {
    console.log('pause()');
    this.timePause = new Date().getTime();
    this.isPaused = true;
    this.encours = false;
  }

  finish() {
    console.log('finish()');
    this.httpClient
      .get(
        environment.api.api + '/DT_viewTemps_json.php?id=' + this.client['id']
      )
      .toPromise()
      .then((json: any) => {
        this.temps = json[0].t;
        console.log('TEMPS : ', this.temps);
        // this.tick();
        this.ecriture();
      })
      .catch((error) => {
        console.log(error);
      });
    this.encours = null;
    this.globalService.toast("C'est bien enregistré, beau travail Fils !");
    this.fermer();
  }

  fermer() {
    console.log('fermer()');
    clearTimeout(this.timer);
    setTimeout(() => {
      this.authService.db
        .collection('decompte')
        .doc(this.decompteID)
        .delete()
        .then((data) => {
          var index = this.jdfService.clients.credit.indexOf(this.client);
          if (index > -1) {
            this.jdfService.clients.credit.splice(index, 1);
          }
        })
        .catch((error) => {
          this.globalService.toast('Erreur sur le live');
        });
    }, 300);
  }

  ecriture() {
    console.log('ecriture()', this.chrono_txt);
    let texte = 'Maintenance';
    if (this.text) {
      texte = this.text;
    }
    // "xml/DT_updateTemps.php?utilisateur_id="+id+"&temps="+temps_temp+"&temps_restant="+n+"&utilisateur_log="+tooroot+"&user="+user+"&txt="+texte_txt.text)
    let variables = 'utilisateur_id=' + this.client['id'];
    variables += '&temps=' + this.temps;
    variables += '&temps_restant=' + this.tempsrestant_txt;
    variables += '&utilisateur_log=tooroot';
    variables += '&user=' + this.authService.user.ics;
    variables += '&txt=' + texte;

    let projetSelected: String;

    if (this.projet != undefined) {
      projetSelected = this.projet.id;
      console.log(this.projet);
    } else {
      projetSelected = '0'; // Si pas de prj sélectionné on envoi 0
    }

    let variableObject = {
      utilisateur_id: this.client['id'],
      projet_id: projetSelected,
      temps: this.temps,
      temps_ajout : this.chrono_txt,
      temps_restant: this.tempsrestant_txt,
      utilisateur_log: 'tooroot',
      user: this.authService.user.ics,
      txt: texte,
      action : 'log',
      categorie_evolution : this.categorieEvolutionSelected
    };

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    });

    this.httpClient
      .post(environment.api.api + '/DT_updateTemps-evol.php', variableObject, {
        headers,
      })
      .toPromise()
      .then((json: any) => {
        console.log('ecriture()', json);

        if (this.isEvolution) {
          // récupration de l'id du dernier log
          let id_last_log = json.id_log;
          this.submitUploadFiles(id_last_log);
        }

        this.recupTemps();
        this.jdfService.recupClients();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ajouter() {
    console.log('ajouter()');
  }


  /*********************************
  * 
  *  Gestion des images évolution
  * 
  **********************************/

  cleanFileName(fileName) {
    // Supprimer les caractères indésirables (tout sauf lettres, chiffres, espaces, points, tirets et underscores)
    let cleanedFileName = fileName.replace(/[^a-zA-Z0-9\s\.\-\_]/g, '');
  
    // Remplacer les espaces par des tirets
    cleanedFileName = cleanedFileName.replace(/\s/g, '-');
  
    // Convertir en minuscules
    cleanedFileName = cleanedFileName.toLowerCase();
  
    // Tronquer le nom de fichier s'il est trop long (max 255 caractères, par exemple)
    cleanedFileName = cleanedFileName.substring(0, 255);
  
    return cleanedFileName;
  }

  getFilePath() {
    const date = new Date();
    const anneeCourante = date.getFullYear();
    const moisCourant = (date.getMonth() + 1).toString().padStart(2, '0');
    let dossier_upload = '';

    if (this.projet) {
      dossier_upload = 'upload-evolution/' + this.cleanFileName(this.client.societe) + '-' + this.client.id + '/' + this.cleanFileName(this.projet.name) + '-' + this.projet.id + '/' + anneeCourante + '/' + moisCourant + '/';
      return dossier_upload;

    } else {
      return false;
    }

  }

  onFileChange(event: Event, type) {
    const inputElement = event.target as HTMLInputElement;
    if (type === 'avant') {
      this.selectedFilesAvant = inputElement.files;
    } else if (type === 'apres') {
      this.selectedFilesApres = inputElement.files;
    }
  }

  submitUploadFiles(id_log) {
    if (this.selectedFilesAvant && this.selectedFilesAvant.length) {
      this.uploadFiles(this.selectedFilesAvant, id_log, 'avant');
    } else {
      console.log('Aucune image sélectionnée.');
    }

    if (this.selectedFilesApres && this.selectedFilesApres.length) {
      this.uploadFiles(this.selectedFilesApres, id_log, 'apres');
    } else {
      console.log('Aucune image sélectionnée.');
    }
  }

  uploadFiles(files: FileList, id_log, type) {
    const formData = new FormData();
    let pathFile = {};
    let fileName = '';

    for (let i = 0; i < files.length; i++) {
      fileName = this.cleanFileName(id_log + '-' + type + '-' + files[i].name);
      formData.append('files[]', files[i], fileName);
    }

    const informationClient = {
      client: this.client,
      projet: this.projet,
      uploadsDir: this.getFilePath(),
      id_log: id_log
    }

    console.log(informationClient)
    formData.append('informationClient', JSON.stringify(informationClient));

    // // Appel de l'API pour enregistrement de l'image
    this.httpClient.post(environment.api.apiv1 + '/upload-images-evolution.php', formData).subscribe(
      (response) => {
        console.log('Files uploaded successfully !', response);
      },
      (error) => {
        console.error('Error uploading files:', error);
      }
    );

  }

  recupCategoriesEvolution() {
    this.httpClient
      .get(
        environment.api.apiv1 + '/categories-evolution.php'
      )
      .toPromise()
      .then((json: any) => {
        this.categoriesEvolution = json.categories;
        console.log(this.categoriesEvolution)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  goToClientPage(id: string) {
    this.router.navigate(['client/view/', id]);
  }
}
