import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController, Platform } from '@ionic/angular';

import { AngularFirestore } from '@angular/fire/firestore';
// import { FirebaseApp } from '@angular/fire';
// import 'firebase/storage';
// import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
// import { filtreModel } from './projet.service';
// import { Badge } from '@ionic-native/badge/ngx';
// import { NotificationService } from './notification.service';
import { GlobalService } from '../services/global.service';
import { UserServiceService } from '../services/user-service.service';
import { environment } from 'src/environments/environment';


// test@test.testfr

export type userModel = {
	userID?: string,
	firstname?: string,
	lastname?: string,
	pseudo?:string,
	email?: string,
	phone?: string,
	url?:string,
	organisation?:string,
	fonction?:string,
	adresse?: string,
	cp?: string,
	commune?: string,
	pays?: string,
	coord?: { latitude: number, longitude: number },
	date_rec?: any,
	statut?:boolean,
	admin?: boolean,
	// filtre?: filtreModel,
	likes?: string[],
	photo?: string,
	seeName?: boolean,
  	seeAddress?: boolean,
  	seePhone?: boolean,
	seeMail?: boolean,
	user_test?: boolean,
	LANG?: string,  
	description_fr?: string,
	description_en?: string,
	ics?: string
}

@Injectable({
	providedIn: 'root'
})
export class AuthentificationService {

	public user:userModel = {};
	public isConnected: boolean = null;
	public authSubject = new Subject();
	public authWindow: Window = null;
	
	public userSubject = new Subject();

	changeEmail;
	changePassword;

	ERRORCONNECT;
	OKCONNECT;

	public enDiscussion=false;

	constructor(
		public db: AngularFirestore,
		public router: Router,
		public afAuth: AngularFireAuth, // Inject Firebase auth service
		public loadingController: LoadingController,
		public alertCtrl: AlertController,
		public translate: TranslateService,
		public toastCtrl: ToastController,
		public platform: Platform,
		// public navCtrl: NavController,
		// public navParams: NavParams,
		//public badge: Badge,
		//public notificationService: NotificationService,
		public globalService: GlobalService,
		public userService: UserServiceService

	) {

		// this.user.userID = '0';

	}



	userHandling() {
		if (!environment.production) console.log('userHandling()');
		this.afAuth.onAuthStateChanged(user => {
			if (user) {
				console.log('userHandling()=>Connected');

				let self = this;
				setTimeout(function(){

					self.db.collection('users').doc(user.uid).valueChanges().subscribe((data) => {
						if (!environment.production) console.log('userHandling()=>Connected=>data', data);
						self.user = data;
						self.userService.user = data;

						self.isConnected = true;
						self.authSubject.next(data);

						self.getNotifications();

						if(self.user.LANG && self.user.LANG != self.globalService.LANG) {
							self.globalService.changeLangue(self.user.LANG);
						}

						if (self.platform.is('cordova')){
							if (!environment.production) console.log('userHandling()=>cordova onesignal init');
							if (!environment.production) console.log(self.user.userID);
							// self.notificationService.initOneSignal(self.user);
						}
					});
				}, 300)
				
			} else {
				console.log('userHandling()=>Not connected');
				this.isConnected = false;
				this.authSubject.next(null);
			}
		});
	}

	async userSignIn(user) {
		let OKCONNECT = this.translate.instant('CONNECT.OKCONNECT');
		return this.afAuth.signInWithEmailAndPassword(user.email, user.password)
		.then((data)=>{
			//auth
			console.log(data);
			this.isConnected = true;
		})
		.catch((error)=>{
			console.log(error);
			switch (error.code) {
				case 'auth/user-not-found':
				  this.globalService.errorAlert('🙄 Frute, t introuvable mec', 'Ton e-mail n\'est pas reconnu', null);
				  break;
				case 'auth/invalid-email':
				  this.globalService.errorAlert('T\'as pas un vrai e-mail 😆 ?', 'Ton e-mail n\'est pas correct', null);
				  break;
				case 'auth/wrong-password':
				  this.globalService.errorAlert('La loose t\'as oublié ton mot de passe 😁', 'remet ton cerveau en place...', null);
				  break;
				default:
				  this.globalService.errorAlert('Oups, une erreur pas connue... : ' + error.code, error.message, null);
				  break;
			  }
			//this.toast('Erreur de connexion : ' + error,'danger');
		});
	}

	async userLogOut() {
		//this.router.navigateByUrl('/out');
		this.user = {};
		this.isConnected = false;
		let LOGOUTED = this.translate.instant('CONNECT.LOGOUTED');
		this.toast(LOGOUTED, 'dark');
		return this.afAuth.signOut().then(()=>{
			window.location.reload();
			//this.user = {};
			//this.isConnected = false;
			//setTimeout(() => {
			//	this.router.navigateByUrl('/out');
			//}, 3000);
		})
	}

	userUpdate(user,toast = true) {
		const MESS_UPDATE = this.translate.instant('INTERFACE.MESS_UPDATE');
		const MESS_ERROR_UPDATE = this.translate.instant('INTERFACE.MESS_ERROR_UPDATE');
		
		this.db.collection('users').doc(user.userID).update(user).then(()=>{
			this.user = user;
			if(toast) this.toast(MESS_UPDATE,'dark'); 
		}).catch(error=>{
			
			this.toast( MESS_ERROR_UPDATE +' : ' + error,'danger');
		})
	}
	async updateUser(element: string, data, userID: string = null) {
		if (!userID) { userID = this.user.userID; }
		console.log('updateUser()', element, data, userID) ;
		if (element === 'email') {
		  //console.log('email entré');
		  await this.afAuth.user;
		  // await this.afAuth.currentUser.updateEmail(data['email']);
		}
		console.log('TO UPDATE', data);
		if (!data) return;
		await this.db.collection('users').doc(userID).update(data).then(()=>{
		  // this.toast('Profil modifié','dark');
		  console.log('Profil modifié');
		}).catch(error=>{
			console.log(error);
		})
	  }

	  createpseudo() {
		return this.user.firstname + ' ' +  this.user.lastname.charAt(0) + '.';
	}

	async userRegister(user, motdepasse) {
		const ERRORCONNECT = this.translate.instant('CONNECT.ERRORCONNECT');
		const OKCONNECT = this.translate.instant('CONNECT.OKCONNECT');
		let self = this;
		console.log('user');
		console.log(user);
		let newUserData;
		let catched = false;
		await  this.afAuth.createUserWithEmailAndPassword(user.email, motdepasse)
			.then(async res => {


				console.log('res', res);

				newUserData = Object.assign(user, {
					userID: res.user.uid,
					pseudo: this.createpseudo(),
					date_rec: Date.now(),
					date_update: Date.now()
				});

				console.log('newUserData');
				console.log(newUserData);
				console.log(this.db);


			}).catch(error=>{
				console.log('Erreur dans 2eme catch')
				this.toast(ERRORCONNECT + ' : ' + error,'danger');
				catched = true;
			});

			if(!catched) {
				this.db.doc('users/'+newUserData.userID).set(newUserData).then((data)=>{

					console.log('dans retour après collection users', newUserData);
					this.user = newUserData;
					console.log('OK dans db.collection users !!!!!!! ', data );
					this.toast(OKCONNECT, 'dark');
				}).catch(error=>{
					console.log('error dans db.collection users !!!!!!! ' +error)
					this.toast(ERRORCONNECT +' : ' + error,'danger');
				});

			}
	}

	
	async getUser(userID: string) {
		//console.log('getUser', userID);
		const user: userModel = {};
		await this.db.collection('users').doc(userID)
		  .get().forEach(async doc => {
			  /*
			for (const key in doc.data()) {
			  user[key] = doc.data()[key];
			}*/
		  });
		return user;
	  }


	clickTest() {
		let user = this.afAuth.currentUser;

		console.log(user);
		console.log(user['email']);
	}

	async updatePassword(password: { old: string, new: string }) {

		const ALERT_PASS_ERROR_TITRE = this.translate.instant('INTERFACE.ALERT_PASS_ERROR_TITRE');
			const ALERT_PASS_ERROR = this.translate.instant('INTERFACE.ALERT_PASS_ERROR');
		const ALERT_ERROR_TITRE = this.translate.instant('INTERFACE.ALERT_ERROR_TITRE');
			const ALERT_ERROR = this.translate.instant('INTERFACE.ALERT_ERROR');
		
			/*
			let user = firebase.auth().currentUser;
			let credential = await firebase.auth.EmailAuthProvider.credential(user.email, password.old);
	
			return firebase.auth().currentUser.reauthenticateWithCredential(credential)
			.then(() => {
				this.toast('update ok ','dark');
				firebase.auth().currentUser.updatePassword(password.new);
			})
			.catch((err) => {
				switch(err.code) {
					case 'auth/invalid-credential':
						this.errorAlert(ALERT_PASS_ERROR_TITRE, ALERT_PASS_ERROR);
					default:
						this.errorAlert(ALERT_ERROR_TITRE, ALERT_ERROR +' ' + err.code);
				}
			});
			*/
		}
	
		async updateEmail(password, email: string) {
	
		const ALERT_PASS_ERROR_TITRE = this.translate.instant('INTERFACE.ALERT_PASS_ERROR_TITRE');
		const ALERT_PASS_ERROR = this.translate.instant('INTERFACE.ALERT_PASS_ERROR');
		const ALERT_ERROR_TITRE = this.translate.instant('INTERFACE.ALERT_ERROR_TITRE');
		const ALERT_ERROR = this.translate.instant('INTERFACE.ALERT_ERROR');
		
			let user = this.afAuth.currentUser;
			/*
			let credential = await firebase.auth.EmailAuthProvider.credential(user.email, password);
	
			let self = this;
	
			return firebase.auth().currentUser.reauthenticateWithCredential(credential)
				.then(() => {
					user.updateEmail(email).then(function() {
						self.user.email = email;
						  self.userUpdate(self.user);
					}).catch(function(error) {
						switch(error.code) {
							case 'auth/invalid-credential':
								self.errorAlert(ALERT_PASS_ERROR_TITRE, ALERT_PASS_ERROR);
							default:
								self.errorAlert(ALERT_ERROR_TITRE, ALERT_ERROR +' ' + error.code);
						}
						return false;
					});
	
				})
				.catch((err) => {
					switch(err.code) {
						case 'auth/invalid-credential':
							this.errorAlert(ALERT_PASS_ERROR_TITRE, ALERT_PASS_ERROR);
						default:
							this.errorAlert(ALERT_ERROR_TITRE, ALERT_ERROR +' ' + err.code);
					}
				});
				*/
		}

	async deleteUser() {

		const DELETED_ACCOUNT = this.translate.instant('CONNECT.DELETED_ACCOUNT');

		/*
		const userID = await firebase.auth().currentUser.uid;
		await firebase.firestore().collection('users').doc(userID).get().then(async doc => {
		  	this.isConnected = false;
		  	this.toast(DELETED_ACCOUNT, 'dark');
		  if (!doc.exists) {
			await this.alertCtrl.create({
			  header: DELETED_ACCOUNT
			}).then(alert => {
			  alert.onDidDismiss().then(async () => {
				this.isConnected = false;
				this.user = {};
				await firebase.auth().currentUser.delete();
				
			  });
			  alert.present();
			});
		  } else {
			await doc.ref.delete();
			await firebase.auth().currentUser.delete();
			this.userLogOut();
		  }
		});
		*/
	}

	

	async errorAlert(title: string, reason: string) {
		const OK = this.translate.instant('BT.OK');
		const alert = await this.alertCtrl.create({
			header: title,
			message: reason,
			buttons: [{
				text: OK,
				role: 'close'
			}]
		});
		await alert.present();
	}

	async forgotPasswordAlert() {

		const PASSE_OUBLIE = this.translate.instant('CONNECT.PASSE_OUBLIE');
		const PASSE_EMAIL = this.translate.instant('CONNECT.PASSE_EMAIL');
		const EMAIL = this.translate.instant('CONNECT.EMAIL');
		const ANNULER = this.translate.instant('BT.ANNULER');
		const VALIDER = this.translate.instant('BT.OK');

		const alert = await this.alertCtrl.create({
		  header: PASSE_OUBLIE,
		  message: PASSE_EMAIL,
		  inputs: [
			{
			  type: 'email',
			  placeholder: EMAIL
			},
		  ],
		  buttons: [
			{
			  text: ANNULER,
			  role: 'close'
			},
			{
			  text: VALIDER,
			  handler: (value) => {
				const mail = value[0];
				var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
				if(this.validateEmail(mail)) {
				  this.forgotPassword(mail);
				}
				else {
				  return false;
				}
	
			  }
			}
		  ]
		});
		await alert.present();
	  }

	forgotPassword(email) {
		return this.afAuth.sendPasswordResetEmail(email);
	}


	FacebookAuth() {
		// return this.AuthLogin(new auth.FacebookAuthProvider());
	}

	// Auth logic to run auth providers
	AuthLogin(provider) {
		let ERRORCONNECT = this.translate.instant('CONNECT.ERRORCONNECT');
		let OKCONNECT = this.translate.instant('CONNECT.OKCONNECT');
		return this.afAuth.signInWithPopup(provider)
		.then((result) => {
			

			let displayName = result.user.displayName.split(" ");

			let firstname = displayName[0];
			let lastname = displayName[1];

			this.user.userID = result.user.uid;
			this.user.email = result.user.email;
			this.user.firstname = firstname;
			this.user.lastname = lastname;

			console.log('result FB auth :::: ');
			console.log(result);

			this.db.collection('users').doc(this.user.userID).set(this.user);

			this.toast(OKCONNECT, 'dark');

			console.log('You have been successfully logged in!');

		}).catch((error) => {
			this.toast(ERRORCONNECT, 'danger');
			this.isConnected = false;
			console.log(error);
		})
	}

	/*inscription() {

		console.log('this.user');
		console.log(this.user);

		if (!this.user.lastname.length || !this.user.firstname.length || !this.user.email.length) {
			this.errorAlert('Champ vide', 'Vous devez remplir tous les champs obligatoires');
			return;
		}
	 
		return firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password);

	}*/

	async setUserInDatabase() {

		this.isConnected = true;

		let OK = this.translate.instant('INTERFACE.OK');

		/*if(this.user.password != undefined) {
			delete this.user.password;
		}*/

		this.toast(OK,'dark'); 

		this.db.collection('users').doc(this.user.userID).set(this.user);

	}

	setUserLangue(language = 'fr') {
		this.user.LANG = language;
		this.db.collection('users').doc(this.user.userID).update({LANG:this.user.LANG});
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	async toast(str,color) {

		const toast = await this.toastCtrl.create({
			message: str,
			duration: 2500,
			position: 'bottom',
			color
		});
		toast.present();
	}
	

	//////////// PLUS ////////////////////


	//////// NOTIFICATIONS ///////
	public nbNotifications = 0; // notifications totale du group
	public nbNotificationsChat = []; // notifications par du chat commun
	public nbNotificationsAlerte = []; // notifications par des Alertes commun
	public notifsCollection:any;
  
	async getNotifications() {
		if (!environment.production) console.log('getNotifications() users/' + this.user.userID + '/notifications');
  
	  	this.notifsCollection = this.db.collection('users/' + this.user.userID + '/notifications');
	  	this.notifsCollection.snapshotChanges().subscribe( async (notifs) =>{
		//this.nbNotifications = actions.length;
		// console.log('getNotifications notifs :', notifs);

		this.nbNotifications = 0; 
		this.nbNotificationsChat = [];
  
		await notifs.forEach(a => {
		  const data = a.payload.doc.data() as any;
		   this.nbNotifications++;
		   if (!environment.production) console.log('getNotifications : ', data.type +' , ' +data.texte, data);			
			if(data.type === 'CHAT'){ 
			  // notifications dans le chat
			  this.nbNotificationsChat.push(data);
			}
			if(data.type === 'ALERT'){ 
				// notifications dans les alertes
				this.nbNotificationsAlerte.push(data);
			}		
		});
		
		if (!environment.production) console.log('nbNotificationsAlerte',this.nbNotificationsAlerte);
		
		if(this.nbNotifications > 0 ){
			// this.notificationService.deleteNotifications('ALERT','', this.user);
			//this.notificationService.deleteNotifications('NEW','', this.user);
			//this.notificationService.deleteNotifications('DATA','', this.user);
		}
		//this.badge.set(this.nbNotificationsChat.length);
		
	  });
	}
	
	
  

}
