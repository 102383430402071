import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, NavParams } from '@ionic/angular';
import { JdfService } from 'src/app/services/jdf.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from 'src/app/services/global.service';
import { Clipboard } from '@capacitor/clipboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-temps-liste',
  templateUrl: './temps-liste.page.html',
  styleUrls: ['./temps-liste.page.scss'],
})
export class TempsListePage implements OnInit {

  public query = '';
  public logs : any;
  public client : any;
  public count = 0;

  constructor(
    public jdfService:JdfService,
    public modalControler:ModalController,
    public httpClient: HttpClient,
    public globalService: GlobalService,
    public navParams:NavParams,
    public router: Router,
  ) { 
    this.client = navParams.get('client');
    console.log(this.client.id);
    this.getListe(this.client.id, 0);
  }

  ngOnInit() {
  }
  dismiss(){
    this.modalControler.dismiss();
  }

  getListe( utilisateur_id, projets_id = 0){
    
    console.log('getListe : ', utilisateur_id,projets_id);
    let lien = environment.api.api +'/DT_export_csv.php?utilisateur_id=' +utilisateur_id +'&projets_id=' +projets_id +'&format=json&forcedownload=false';
   
      this.httpClient
        .get(lien)
        .toPromise()
        .then(async (liste: any) => {
          console.log('liste : ', liste);
          this.logs = [];
            liste.forEach(element => {
              element['checked'] = false;
              this.logs.push(element);
            });
            // this.logs = liste;
            this.count = this.logs.length;

           // this.logs = JSON.stringify(liste);

          

        }).catch(async (error) => {
          this.globalService.toast(error.message);
          console.log(error);
        });
    
  }

  update(item){
    console.log('update',item);
    this.jdfService.selectedClient(item);
  }
  clickCopies(){
    console.log('clickCopies', this.logs);
    let chekedList = [];
    this.logs.forEach(element => {
      if(element.checked) chekedList.push(element);
    });
    if(chekedList.length >0 ){
      this.clickCopy(chekedList);
    }else{
      this.globalService.toast('Oups... aucun élément sélectionné !');
    }
  }



  async clickCopy(items){
    console.log('clickCopy',items);

    let string = '';

    if(items.length==1) {
      string += 'Date : ' + items[0].date +"\n"; 
      string += 'Projet : ' + items[0].projets_titre +"\n"; 
      string += 'Acteur : ' + items[0].acteur +"\n"; 
      string += 'Temps passé : ' + items[0].temps_passe +"\n"; 
      string += 'Crédit : ' + items[0].credit +"\n"; 
      string += 'Description :\n'; 
      string += items[0].description ; 
    }else{
      items.forEach(item => {
        string += item.acteur +' | Temps passé : ' + item.temps_passe +"\n"; 
        string += item.description +"\n\r";
      });
    }



    await Clipboard.write({
      string
    });
    this.globalService.toast('Texte copié dans le presse papier');
  }


  filterResult(event,quelleliste) {
    // console.log(event.detail.value)
    this.query = event.detail.value;
    const items = Array.from(document.getElementById(quelleliste).children);
    const query = this.query.toLowerCase();
    if(items){
      items.forEach( (item: any) => {
        item.style.display = (item.textContent.toLowerCase().indexOf(query) > -1) ? 'block' : 'none';
      });
    }
} 

  goToLogPage(id: string) {
    console.log(id);
    this.router.navigate(['client/log/', id]);
    this.dismiss();
  }

}
