import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserConnectedGuard } from './guards/user-connected.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'page404',
    loadChildren: () => import('./pages/page404/page404.module').then( m => m.Page404PageModule)
  },
  {
    path: 'attente',
    loadChildren: () => import('./pages/auth/attente/attente.module').then( m => m.AttentePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/auth/account/account.module').then( m => m.AccountPageModule),
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'out',
    loadChildren: () => import('./pages/auth/out/out.module').then( m => m.OutPageModule),
  },
  {
    path: 'todo',
    loadChildren: () => import('./pages/todo/todo.module').then( m => m.TodoPageModule),
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'client/view/:id',
    loadChildren: () => import('./pages/client/view/view.module').then( m => m.ViewPageModule),
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'client/log/:id',
    loadChildren: () => import('./pages/client/log/log.module').then( m => m.LogPageModule)
  },
  {
    path: '**',
    redirectTo: '/page404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
