import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthentificationService } from './services/authentification.service';
import { GlobalService } from './services/global.service';
import { config } from './../environments/config';
import { environment } from './../environments/environment';

/*
const electron = require('electron')
*/
// import { NgxElectronPlusService } from 'ngx-electron-plus';
// import * as electron from 'electron';
// import { BrowserWindow } from 'electron';
//import * as fs from 'fs';

/*
import { Plugins } from '@capacitor/core';
const { LocalNotifications, Clipboard, Modals } = Plugins;
import { ElectronService } from 'ngx-electron';
*/

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public config = config;
  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private globalService: GlobalService,
    public authService: AuthentificationService,
    // private browserWindow: BrowserWindow,
    // private electronPlusService: NgxElectronPlusService
    // private electronService: ElectronService
  ) {
    this.initializeApp();
  }

  initializeApp() {


    console.log('initializeApp()');
    console.log(this.platform.platforms());


    //let BrowserWindow = electron.BrowserWindow;
    // electron
    //new BrowserWindow({
    //  width: 800,
    //  height: 600,
    //  minHeight: 300,
    //  minWidth: 300,
    //})

    /*
    if (this.electronService.isElectronApp) {
      console.log('I AM ELECTRON');
      this.electronService.ipcRenderer.on('trigger-credits', this.showElectronAlert);
      this.electronService.ipcRenderer.on('trigger-jdf', this.goToJDF);
      this.electronService.ipcRenderer.on('trigger-agrid', this.goToAgrid);
    }
    */

    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();

      if(this.platform.is('electron')){
        
        console.log('electron');
        // let BrowserWindow =  electron.BrowserWindow;
      }

      this.checkDarkTheme();
      this.globalService.changeLangue('fr');
      this.authService.userHandling();
    });
  }
  async goToJDF(){
    window.open(environment.api.site, '_blank', 'top=500,left=200,frame=false,nodeIntegration=no');
  }
  async goToAgrid(){
    window.open(environment.api.agrid, '_blank', 'top=500,left=200,frame=false,nodeIntegration=no');
  }
  async openWindow(url){
    console.log('openWindow',url);
    window.open(url, '_blank', 'top=500,left=200,frame=false,nodeIntegration=no')
  }
  async showElectronAlert() {
   /* Modals.alert({
      title: 'Hello!',
      message: 'Crédits : John et ses fils\r\nVersion : '+config.version
    });*/
  }

  checkDarkTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    console.log('checkDarkTheme()', prefersDark);
    //if ( prefersDark.matches ) {
    //  document.body.classList.toggle( 'dark' );
    //}
    document.body.classList.toggle( 'dark' );
  }
}
