// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'prod',
  mode: 'live',
  firebase : {
    apiKey: "AIzaSyCc5RQ9q_B1buB4P_IFDHkxnZc_01ZaJ0M",
    authDomain: "johndoe-et-fils.firebaseapp.com",
    databaseURL: "https://johndoe-et-fils.firebaseio.com",
    projectId: "johndoe-et-fils",
    storageBucket: "johndoe-et-fils.appspot.com",
    messagingSenderId: "926287540898",
    appId: "1:926287540898:web:28540deddc95ad2bf65dfb"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoicGFydGVkaXMiLCJhIjoiY2tuZnJwcGhmMnk1NzJubnhzeW00MDZ3YyJ9.9UOBPuetSrAxsJmTsCvx7Q',
    style : 'mapbox://styles/partedis/cknrgwvax0s2p17ufy9sinqmj'
  },
  api:{
    site: 'https://johndoe-et-fils.com',
    agrid: 'https://agrid.johndoe-et-fils.com',
    api: 'https://johndoe-et-fils.com/xml',
    apiv1: 'https://johndoe-et-fils.com/xml/api/v1',
  },
  asana:{
    api: 'https://app.asana.com/api/1.0'
  }
};
/*
workspace : 1171162238645431
1/1171161088564287:cd096dafe2e2bc5b77332afc49924800
project : 1198886461735888
user : 1171161088564287
1171162238645429
*/