import { Injectable } from '@angular/core';
import { ToastController, ActionSheetController, LoadingController, Platform } from '@ionic/angular';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
import { AngularFireStorage } from '@angular/fire/storage';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  public annuaire_Collection: AngularFirestoreCollection<any>;
  public annuaire_Contact: Observable<any>;
  public annuaire: any = [];
  public annuaireVerif: any = [];
  public tags: any = [];
  public clients: any = [];
  public clientEnCours: any;
  public annuaireSubject = new Subject();

  public signalements_typesCollection: any;
  public signalements_types: any;

  public is_connected = false;

  public currentUser: any; // userde connecion firebase pour userID

  public start: Date;
  public end: Date;
  public start_ion: Date;
  public end_ion: Date;

  // pour admin
  public users_Collection: AngularFirestoreCollection<any>;
  public users: any; // Observable<any>;
  public usersVerif: any = {};

  userDoc: any;
  public user: any = {
    firstname: '',
    name: '',
    adresse: ' ',
    phone: '',
    email: '',
    userID: '',
    admin: true,
    statut: false
  };

  private loading: any;
  // variables pour photos ///////////////////
  public photo_selectedPhoto: any;
  public photo_chemin: any;
  public photo_item: any; // temporaire

  //////////////////////////////////////////

  constructor(
    private toastCtrl: ToastController,
    public afs: AngularFirestore,
    public actionSheetCtrl: ActionSheetController,
    private camera: Camera,
    public loadingCtrl: LoadingController,
    private callNumber: CallNumber,
    private lesms: SMS,
    private launchNavigator: LaunchNavigator,
    public storage : AngularFireStorage,
    public platform: Platform,
  ) {
    this.getType();
    const now = new Date();
    // this.start = now;
    // this.end = now;
    // this.start = new Date(now.getFullYear() + '-' + Number(now.getMonth() + 1));
    // this.end = new Date(now.getFullYear() + '-' + Number(now.getMonth() + 2));
    // console.log('Start',this.start);
    // console.log('end',this.start);
  }

  async toast(str) {
    const toast = await this.toastCtrl.create({
      message: str,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }
  async chargement() {
    this.loading = await this.loadingCtrl.create({
      message: 'Veuillez patienter...'
    });
    this.loading.present();
  }


  sms(qui){
		// console.log("sms()",qui);
		const options = {
			replaceLineBreals: false,
			android: {
				intent: 'INTENT' 
			}
  }
    if (this.platform.is('ios')){
      this.lesms.send(qui.phone, 'Bonjour ' + qui.name + ',', options);
    }else{
      const uri = 'sms://' + qui.phone ;
      //const uri = 'sms:' + qui.phone+ '/?body='+this.translate.instant('SMS') + qui.pseudo;
      // console.log("uri :", uri);
      window.open(uri, '_system');
    }
  }

  // call(qui) {
  //   console.log('call()', qui);
  //   this.callNumber.callNumber(qui.phone, true)
  //     .then(res => console.log('Launched dialer!', res))
  //     .catch(err => console.log('Error launching dialer', err));
  // }

  call(qui){
    // console.log("call()",qui);
    if (this.platform.is('ios')){
		  this.callNumber.callNumber(qui.phone, true)
		  .then(res => console.log('Launched dialer!', res))
		  .catch(err => console.log('Error launching dialer', err));
    }else{
      const uri = 'tel://' + qui.phone ;
		  // console.log("uri :", uri);
		  window.open(uri, '_system');
    }
  }

  mail(qui) {
    console.log('mail()', qui);
    window.location.href = 'mailto:' + qui.email + '?body=Bonjour '
      + qui.firstname + ' ' + qui.name + ',&subject=De la part de ' + this.user.firstname + ' ' + qui.name;
  }

  itineraire(qui) {

    console.log('itineraire()', this.platform.is('ios'), qui);
      // console.log("iti :: ",qui);
      let uri = 'maps://?ll=' + qui.adresse ;
      if (this.platform.is('ios')){
        this.launchNavigator.navigate( qui.adresse + ',' + qui.cp + ',' + qui.ville);
      }else{
        uri = 'https://www.google.com/maps/search/' + qui.adresse+ ',' + qui.cp + ',' + qui.ville  ;
        window.open(uri, '_system');
      }
    }


  getProfil(uid) {
    console.log('getProfil(' + uid + ')');
    this.userDoc = this.afs.doc('users/' + uid).valueChanges();
    return new Promise(resolve => {
      this.userDoc.subscribe(data => {
        console.log(data);
        if (data) {
          this.user = data;
          this.getType();
        }
        resolve('ok');
      });
    });
  }

  getEquipe() {
    console.log('getEquipe()');
    this.users_Collection = this.afs.collection('users', ref => ref);
    this.users_Collection.snapshotChanges()
      .subscribe(actions => {
        this.users = [];
        actions.map(a => {
          // console.log('equipe -->', a.payload.doc.data());
          const data = a.payload.doc.data();
          const id = a.payload.doc['id'];
          this.usersVerif[id] = data;
          this.users.push({ id, ...data });
          // return { id, ...data };
        });
      });
  }

  updateEquipe(item) {
    console.log('updateEquipe', item);
    if(!item.statut){
      item.statut = false;
      item.admin = false; // je passe non admin si dévalidé
    }
    if(!item.admin){
      item.admin = false;
    }
    this.afs.doc('users/' + item.userID).update({
      admin: item.admin,
      statut: item.statut,
    }).then(res => {
      console.log('ok');
    }).catch(err => {
      console.log('ko');
    });
  }
  /*
  updateUser(userUpdate,toasteur:boolean=true){
		let PROFILOK:string ;
		this.translate.get('USER.PROFILOK').subscribe(value => { PROFILOK = value;});

		this.postDoc.update(userUpdate).then(data=>{
			if(toasteur)this.toast(PROFILOK);
		});
	 }
  */

  getType() {
    console.log('getType()');
    this.signalements_typesCollection = this.afs.collection('signalements_types', ref => ref.orderBy('ordre', 'asc')); // ref()
    this.signalements_typesCollection.get().subscribe(data => {
      console.log('data', data);
      let document: any;
      this.signalements_types = [];
      data.forEach((doc) => {
        console.log(doc.id);
        document = doc.data();
        document.typeID = doc.id;
        this.signalements_types.push(document);
        // return document;
      });
    });
  }

  getAnnuaire() {
    console.log('getAnnuaire');
    if (!this.annuaire_Collection) {
      if(environment.production){
        this.annuaire_Collection = this.afs.collection('annuaire', ref => ref.orderBy('societe', 'desc')
        );
      }else{
        this.annuaire_Collection = this.afs.collection('annuaire', ref => ref.orderBy('societe', 'desc')
        .limit(10)
        );
      }

      this.annuaire_Collection.snapshotChanges().subscribe(data => {
        // console.log('->', data);
        this.annuaire = [];
        this.tags = [];
        data.forEach(element => {
          // console.log('ELEMENT ', element);
          const donnee = element.payload.doc.data();
          const id = element.payload.doc['id'];
          donnee.id = element.payload.doc['id'];
          donnee.tags.forEach(tag => {
            // console.log('création tag', tag);
            if ( this.tags.indexOf(tag) === -1 ) {
              this.tags.push(tag);
            }
          });
          this.annuaireVerif[id] = donnee;
          this.annuaire.push(donnee);

          if(donnee.tags.includes('client')){
            this.clients.push(donnee);
          }
        });
        this.annuaireSubject.next(data);
      });
    }
  }
  ////////////////////////////////////////////////////
  //// PHOTOS /////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////


  takePhoto(chemin, options) {
    console.log('takePhoto()',chemin,options);
    this.photo_chemin = chemin;

    return new Promise(resolve => {
      console.log('takePhoto()promise');
      this.camera.getPicture(options).then((imageData) => {
        this.chargement();
        console.log('takePhoto()data'+ imageData);
        this.photo_selectedPhoto = this.dataURItoBlob('data:image/jpeg;base64,' + imageData);
        this.upload().then(res => {
          resolve(res);
        }).catch(error => {
          resolve({ action: 'ko', error: error });
        });
      }, (error) => {
        console.log('error', error);
        resolve({ action: 'ko', error: error });
      });

    }); // fin promise

  }
  dataURItoBlob(dataURI) {
    console.log('dataURItoBlob()');
    // code adapted from: http://stackoverflow.com/questions/33486352/cant-upload-image-to-aws-s3-from-ionic-camera
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  upload() {

    return new Promise(resolve => {

      console.log('upload()');
      if (this.photo_selectedPhoto) {
        const d = new Date();
        const date_prise = d.getFullYear()
          + '-' + d.getMonth()
          + '-' + d.getDate()
          + '-' + d.getHours()
          + '-' + d.getMinutes()
          + '-' + d.getSeconds();

        // let referenceImage = 'lots/'+this.userService.currentUser.uid+'/'+date_prise+'.jpg';
        const referenceImage = this.photo_chemin + '/' + date_prise + '.jpg';
        console.log('upload()'+referenceImage);
        // var uploadTask = firebase.storage().ref().child(referenceImage).put(this.selectedPhoto)
        
        this.storage.storage.ref().child(referenceImage).put(this.photo_selectedPhoto)
          .then(snapshot => {
            return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
          })
          .then(downloadURL => {
            console.log('onSuccess downloadURL : ' + downloadURL);
            this.loading.dismiss();
            resolve({ action: 'ok', downloadURL: downloadURL, referenceImage: referenceImage });

          })
          .catch(error => {
            // Use to signal error if something goes wrong.
            console.log('error', error);
            this.loading.dismiss();
            resolve({ action: 'ko', error: error });
          });
      }
      
    }); // fin promise
  }
}
