import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { GlobalService } from 'src/app/services/global.service';
import { JdfService } from 'src/app/services/jdf.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-temps-update',
  templateUrl: './temps-update.page.html',
  styleUrls: ['./temps-update.page.scss'],
})
export class TempsUpdatePage implements OnInit {

  environment = environment
  
  public client;
  public action : 'ajout' | 'suppression' | 'reset'| 'update'; // "ajout" , "update"
  public projets;
  public selectedProjet;
  public oldCredit;// tableau
  public old2Credit;// tableau
  public oldMinutes:number; // calcul en minutes
  public newCredit; // tableau
  public newCredit_txt:String;  
  public addCredit_txt:String;  
  public newMinutes:number; // calcul en minutes
  public addMinutes:number; // calcul en minutes
  public heures_input = 0;
  public minutes_input = 0;
  public textUpdate = '';
  public ladate  = Date();
  // public ladate  = "2023-01-26T16:42:21.802+01:00"

  constructor(
    public jdfService:JdfService,
    public modalControler:ModalController,
    public navParams:NavParams,
    public authService:AuthentificationService,
    public httpClient: HttpClient,
    public globalService: GlobalService,
  ) { 
    this.client = navParams.get('client');
    this.action = navParams.get('action');
    this.newCredit = this.client.temps.split(':');
    this.old2Credit = this.client.temps.split(':');
    this.textUpdate = '';
    console.log('action: ', this.action);

    if (this.action == 'suppression')  this.textUpdate = 'Ajustement du crédit temps';
    if (this.action == 'reset')  this.textUpdate = 'Reset';
    if (this.action == 'ajout')  this.textUpdate = 'Ajout de crédit temps';

    this.calcul();
  }
  ngOnInit() {
    this.getProjets();
  }
  ladateChange(event){
    console.log('ladateChange', event, this.ladate);
  }
  getProjets() {
    this.httpClient.get(environment.api.api+'/DT_viewProjets_json.php?id='+this.client['id']).toPromise()
    .then( (json:any)=> {
      this.projets= json;
      console.log('PROJETS : ',this.projets);
    })
    .catch(error => {
      console.log(error);
    })
  }
  updateSearchResults(event) {
    let search: String;
    search = event.detail.value; 

    if (search !== '') {
      this.projets = this.projets.filter( projet =>
        projet.name.toLowerCase().includes(search.toLowerCase()) || projet.id.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      this.getProjets();
    }
  }
  ionSelectProject(event){
    this.selectedProjet = event.detail.value;
  }
  selectProjet(prjId) {
    this.selectedProjet = prjId;
  }
  dismiss(){
    this.modalControler.dismiss();
  }
  delete(){
    console.log('delete()');
  }
  change(event,qui){
    this[qui]=event.detail.value;
    this.calcul();
    console.log(event);
  }
  calcul(){
    this.oldCredit = this.client.temps.split(':');
    
    console.log(this.newCredit);
    this.oldMinutes = Number(this.oldCredit[0])*60 + Number(this.oldCredit[1]);
    this.newMinutes = Number(this.old2Credit[0])*60 + Number(this.old2Credit[1]);
    this.addMinutes = Number(this.heures_input*60) + Number(this.minutes_input);
    if(this.action == "ajout"){
      this.newMinutes += this.addMinutes;
    }else{
      this.newMinutes -= this.addMinutes;
    }
    this.convert();
  }
  zero(){
    this.heures_input = 0;
    this.minutes_input = 0;
    this.newMinutes = 0;
    this.newCredit = [0,0,0];
    this.old2Credit = [0,0,0];
    this.calcul()
    
  }
  convert(){
    // nouveau temps
    if(this.newMinutes<0) this.newMinutes = 0;
    let h:any = Math.floor(this.newMinutes/60);
    let m:any = this.newMinutes - h*60;
    
    if(h<10) h = '0'+h;
    if(m<10) m = '0'+m;
    
    this.newCredit[0] = h;
    this.newCredit[1] = m;
    
    this.newCredit_txt = h+':'+m+':00';

    // ajout
    if(this.addMinutes>=0){
      
      let addh:any = Math.floor(this.addMinutes/60);
      let addm:any = this.addMinutes - addh*60;
      
      if(addh<10) addh = '0'+addh;
      if(addm<10) addm = '0'+addm;
      
      this.addCredit_txt = addh+':'+ addm ;
    } else{
      this.addMinutes = 0;
      this.addCredit_txt = '';
    }
    console.log('this.addCredit_txt : ' +  this.addCredit_txt)
  }
  recharge(){
    this.newCredit = this.oldCredit;
    this.old2Credit = this.oldCredit;
    this.newMinutes = 0;
    this.calcul();
  }
  ecriture(){
    
    // "xml/DT_updateTemps.php?utilisateur_id="+id+"&temps="+temps_temp+"&temps_restant="+n+"&utilisateur_log="+tooroot+"&user="+user+"&txt="+texte_txt.text)
    
    let action = this.action ;
    console.log('ecriture()', action);

    let variables = 'utilisateur_id='+this.client['id'];
    variables += '&projet_id='+this.selectedProjet;
    variables += '&temps='+this.newCredit_txt;
    variables += '&temps_restant='+this.newCredit_txt;
    variables += '&temps_ajout='+this.addCredit_txt+':00';
    variables += '&utilisateur_log=tooroot';
    variables += '&user='+this.authService.user.ics;
   /* if(this.newMinutes == 0){
      action = 'reset';
      variables += '&txt=Remise à zéro';
      variables += '&type='+action;
    }else{*/
    /*
      if(this.addCredit_txt == '' ){
        //action = 'suppression';
        variables += '&txt=Ajustement du crédit temps';
        variables += '&type='+action;
      }else{
        //action = 'ajout';
        variables += '&txt=Ajout de crédit temps '+this.addCredit_txt;
        variables += '&type='+action;
      }
      */
    //}
     // converstion date en MYSQL
     let date = new Date(this.ladate); //new Date();
     let year = date.getFullYear();
     let month = date.getMonth() + 1; // Les mois sont compris entre 0 et 11
     let day = date.getDate();
     let hour = date.getHours();
     let minutes = date.getMinutes();
     let seconds = date.getSeconds();
     
     let datetime = year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;



    let variableObject :any;
    if(action == "ajout"){
      variableObject = {
        utilisateur_id: this.client['id'],
        projet_id : this.selectedProjet,
        temps : this.newCredit_txt,
        temps_restant : this.newCredit_txt,
        temps_ajout : this.addCredit_txt+':00',
        utilisateur_log: 'tooroot',
        user: this.authService.user.ics,
        action,
        txt: this.textUpdate + ' ' + this.addCredit_txt
      };
    }else{
      variableObject = {
        utilisateur_id: this.client['id'],
        projet_id : this.selectedProjet,
        temps : this.newCredit_txt,
        temps_restant : this.newCredit_txt,
        temps_ajout : this.addCredit_txt+':00',
        utilisateur_log: 'tooroot',
        user: this.authService.user.ics,
        date_rec: datetime,
        action,
        txt: this.textUpdate
      };
    }



    
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    });

    this.httpClient.post(environment.api.api + '/DT_updateTemps.php', variableObject, {
        headers,
      })
      .toPromise()
      .then((json: any) => {
        console.log('ecriture()', json);
        //this.temps= json[0].t;
       // this.recupTemps();
        this.jdfService.recupClients();
        this.globalService.toast('Enregistrement 👍');
        // écriture dans de la tache
       if(action==='ajout'){
        const todoID = this.globalService.generateUniqueFirestoreId();
        const todo_doc = {
          client: this.client,
          client_agridID : this.client['id'],
          user_agridID : this.authService.user['agridID'],
          userID : this.authService.user['userID'],
          temps_add: this.addCredit_txt,
          temps_restant: this.newCredit_txt,
          user : this.authService.user,
          date_rec : new Date(),
          action,
          type : 'credit_temps',
          todoID
        }
        this.authService.db.collection('todo').doc(todoID).set(todo_doc);
         this.modalControler.dismiss({ temps : this.newCredit_txt });
      }
      this.modalControler.dismiss({ temps : this.newCredit_txt });
      })
      .catch((error) => {
        console.log(error);
        this.globalService.toast('Erreur à l\'enregistrement, sorry');
      });
  


/*
    this.httpClient.get(environment.api.api+'/DT_updateTemps.php?'+variables).toPromise()
    .then( (json:any)=> {
      console.log('ecriture()', json);
      //this.temps= json[0].t;
      console.log('ecriture : ',json);
      this.jdfService.recupClients();
      this.globalService.toast('Enregistrement 👍');
       // écriture dans de la tache
       if(action==='ajout'){
         const todoID = this.globalService.generateUniqueFirestoreId();
         const todo_doc = {
           client: this.client,
           client_agridID : this.client['id'],
           user_agridID : this.authService.user['agridID'],
           userID : this.authService.user['userID'],
           temps_add: this.addCredit_txt,
           temps_restant: this.newCredit_txt,
           user : this.authService.user,
           date_rec : new Date(),
           action,
           type : 'credit_temps',
           todoID
         }
         this.authService.db.collection('todo').doc(todoID).set(todo_doc);
       }

      this.modalControler.dismiss({ temps : this.newCredit_txt });
    })
    .catch(error => {
      console.log(error);
      this.globalService.toast('Erreur à l\'enregistrement, sorry');
    })*/
  }

}
