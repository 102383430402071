export const config = {
    version : '0.3.3',
    ppp : 25, // product per page 
    socialnetwork : [
        { code :'facebook', url : 'https://www.facebook.com/johndoeetfils' },
        { code :'instagram', url : 'https://www.instagram.com/johndoe_et_fils' },
        { code :'twitter', url : 'https://twitter.com/johndoe_et_fils' },
        { code :'linkedin', url : 'https://www.linkedin.com/company/2407605' },
        { code :'vimeo', url : 'https://vimeo.com/johndoeetfils' },
    ]
};