import { NavController } from '@ionic/angular';
import { AuthentificationService } from './../services/authentification.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserConnectedGuard implements CanActivate {

  constructor(
    public authService: AuthentificationService,
    public navCtrl: NavController,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let canPass = null;
    await new Promise<Object>(async (resolve) => {
      if (this.authService.isConnected === true) { 
        resolve( { isConnected: true, user: this.authService.user } );
      }
      if (this.authService.isConnected === false) {
        resolve({ isConnected:false, user: null });
      }
      if (this.authService.isConnected === null) {
        const temp = await this.authService.authSubject.subscribe(async () => {
          if (this.authService.isConnected === false) {
            resolve( { isConnected:false, user: null } );
          }
          await temp.unsubscribe();
          resolve({ isConnected: true, user: this.authService.user });
        });
      }
    }).then(value => {
      canPass = value;
    });
    if (!environment.production) console.log('A----> ', canPass);
    if (!canPass.isConnected) {
      // je suis dois me loggué
      if (!environment.production) console.log('B----> ', canPass);
      this.navCtrl.navigateRoot('/login');
      return false;
    }else{
      if (!canPass.user.statut) {
        // je suis en attente
        if (!environment.production) console.log('C----> ', canPass);
        this.navCtrl.navigateRoot('/attente');
        return false;
      }else{
        // je passe
        if (!environment.production) console.log('D----> ', canPass);
        return true;
      }
    }
    // return canPass.isConnected;
  }
}
