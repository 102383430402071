import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { JdfService } from 'src/app/services/jdf.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.page.html',
  styleUrls: ['./clients.page.scss'],
})
export class ClientsPage implements OnInit {

  public query = '';

  constructor(
    public jdfService: JdfService,
    public modalControler: ModalController,
    public router: Router
  ) { }

  ngOnInit() {
  }
  dismiss(){
    this.modalControler.dismiss();
  }


  filterResult(event,quelleliste) {
    // console.log(event.detail.value)
    this.query = event.detail.value;
    const items = Array.from(document.getElementById(quelleliste).children);
    const query = this.query.toLowerCase();
    if(items){
      items.forEach( (item: any) => {
        item.style.display = (item.textContent.toLowerCase().indexOf(query) > -1) ? 'block' : 'none';
      });
    }
}

  goToClientPage(id: string) {
    this.router.navigate(['client/view/', id]);
  }

}
