import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { config } from 'process';
// import { ClientsPage } from '../modals/clients/clients.page';


export type clientModel = {
	id: string,
	prenom?: string,
	nom?: string,
	societe?: string,
	temps?: string,
  date_rec?:any
};

export type clientsModel = {
  tous:clientModel[],
  liste:clientModel[],
  credit:clientModel[],
  evolution:clientModel[],
  projets:clientModel[],
};

@Injectable({
  providedIn: 'root'
})
export class JdfService {

  public clients:clientsModel = {
    tous:[],
    liste:[],
    credit:[],
    evolution:[],
    projets:[],
  }; // liste des clients
  public client:any; // client sélectionné
  public clientsDecompte = []; // les  clients sélectionné pour le crédit temps
  public todo = [];

  // live
  public live:any = []; // liste des user en live
  public decompteIDencours: string;

  constructor(
    public httpClient: HttpClient,
    public db: AngularFirestore,
    public popoverController:PopoverController,
    public modalController:ModalController,
    public alertController:AlertController,
  ) { }

 //////////////////////////////// activeLeLive() ///////////////////////////
  activeLeLive( userID ){
    if (!environment.production) console.log("====== activeLeLive() =======")
    this.db.collection('decompte').valueChanges().subscribe((data)=>{
      this.live = data;
      // suppression des doublons
      this.live.forEach(element => {
        // console.log('suppression : ' , element, element.user.userID,  userID  ); 
         if(userID == element.user.userID ){ 
          // si ce n'est pas celui en cours
           if(element.decompteID != this.decompteIDencours){
               this.deleteUnLive(element);
           }
         }
      });
      // console.log(this.live);
    });
  }
  deleteUnLive(item){
    console.log('deleteUnLive(',item);
    this.db.collection('decompte').doc(item.decompteID).delete();
  }

  async deleteLive(item) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Tu veux supprimer le log ?',
      message: 'Ce live est bizarre !!!',
      buttons: [
        {
          text: 'Non !',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            this.deleteUnLive(item);
          }
        }
      ]
    });

    await alert.present();
  }
  /////////////////////////////////////////////////////////////////////////////////


  selectedClient(client){
    console.log('selectedClient', client);
    ///this.client = client;
    this.clients.credit.push(client);
  }

  viewClient(client){
    console.log('viewClient', client);
  }

  recupClients(){
    if (!environment.production) console.log('recupClients');
    this.httpClient.get(environment.api.api+'/DT_viewClients_json.php').toPromise()
    .then( (json:any)=> {
      this.clients.tous = json;
      if (!environment.production) console.log(this.clients);
    })
    .catch(error => {
      console.log('recupClients error : ', error);
    })
  }

  async getClients(){
    await this.httpClient.get(environment.api.apiv1+'/user.php').toPromise()
    .then( (json:any)=> {
      this.clients.liste = json ;
    })
    .catch(error => {
      console.log('getClients error : ', error);
    })
  }

  async getProjetsClient(id_client){
    await this.httpClient.get(environment.api.apiv1+'/projets.php?id='+id_client).toPromise()
    .then( (json:any)=> {
      this.clients.projets = json ;
    })
    .catch(error => {
      console.log('getClients error : ', error);
    })
  }

  recupToDo(){
    this.db.collection('todo').valueChanges().subscribe((data)=>{
      this.todo = data;
      if (!environment.production) console.log(this.todo);
    })
  }

  /*
  verifUser(){
    console.log("verifUser()");
    // user = user_mc.user_txt.text;
    // liste_clients.visible=false;
    // clock.visible = false;
    // boutons.visible = false;
    this.global.toast('Vérification de l\'utilisateur');
    // Divers.chargeXML(chemin+"xml/DT_user.php?user="+user,chargeUserOk);
  }


  start(){
    let countDownDate = new Date("May 10, 2021 14:50:25").getTime();

    // Update the count down every 1 second
    let x = setInterval(function () {

      // Get todays date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      console.log(now, "now", "countDownDate", countDownDate, "distance", distance, "days", days);

      // Output the result in an element with id="demo"
      document.getElementById("demo").innerHTML = days + "d " + hours + "h "
        + minutes + "m " + seconds + "s ";

      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
  }
  */
}
