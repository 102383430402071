import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';  // <<<< import it here

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ComponentsModule } from './components/components.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';

/// PROVIDERS
import { Camera } from '@ionic-native/camera/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

// connexion AUTH
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireDatabaseModule, AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from '@angular/fire/storage';

// TRAD :
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core/';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function customTranslateLoader(http: HttpClient) {
  // console.log('customTranslateLoader');
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// TRAD DATES
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr,'fr'); // fr-FR

// modals
//////// MODALS
// import { ClientsPageModule } from '../app/modals/clients/clients.module';
// pages
import { ClientsPage } from '../app/modals/clients/clients.page';
import { TempsUpdatePage } from '../app/modals/temps-update/temps-update.page';
import { TempsListePage } from '../app/modals/temps-liste/temps-liste.page';
//import { TempsUpdatePageModule } from '../app/modals/temps-update/temps-update.module';

//// MACOSX && WINDOWS ///
// import { NgxElectronModule } from 'ngx-electron';
// import { NgxElectronModule}  from 'ngx-electron-slim';


@NgModule({
  declarations: [
    AppComponent,
    ClientsPage,
    TempsUpdatePage,
    TempsListePage
  ],
  entryComponents: [
    ClientsPage,
    TempsUpdatePage,
    TempsListePage
  ],
  imports: [
    // NgxElectronModule,
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
  ],
  providers: [
    AngularFireAuthModule,
    Camera,
    CallNumber,
    SMS,
    InAppBrowser,
    LaunchNavigator,
    { provide: LOCALE_ID, useValue: "fr-FR" },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
