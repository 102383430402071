import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ModalController, Platform, ToastController, PopoverController,AlertController,LoadingController } from '@ionic/angular';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

// cordova
// import { CarteLocalisationPage } from '../modals/carte-localisation/carte-localisation.page';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
//import { SocialSharing } from '@ionic-native/social-sharing/ngx';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public version = '1.0.0';

  public iphoneX = false;

  public LANG = 'fr';
  public langues = ['fr','en'];

  public locale = 'fr-FR';

  // variables pipes
  public stringDivision =  ''; // pour gérer les divisions dans la pipe
  

  constructor(
    public router: Router,
    public alert: AlertController,
    public loading: LoadingController,
    public db: AngularFirestore,
    public modalCtrl: ModalController,
    public platform : Platform,
    public toastCtrl: ToastController,
    public alertController: AlertController,
    public popoverCtrl: PopoverController,
    private title: Title,
    private meta: Meta,
    public translate: TranslateService,
    public storage: AngularFireStorage,
    // cordova
    public iab: InAppBrowser,
    private lesms: SMS,
    private callNumber: CallNumber,
    // private socialSharing: SocialSharing,
  ) { 
  }
  
  	/////////////////// GESTION DE LA VERSION PWA  /////////////////
    verificationVersion(){
      console.log('verificationVersion');
      this.db.doc('variables/vars').valueChanges().subscribe( async (data:any) =>{
        console.log('verificationVersion',data);
        if(data.version !== this.version){
          console.log('rechargement');
          // "Veuillez mettre à jour votre application SVP",
          const RELOAD_TITRE = 'Nouvelle version'; //this.translate.instant("VERSIONING.RELOAD_TITRE");
          const RELOAD_TEXTE = 'Veuillez recharger la page SVP'; //this.translate.instant("VERSIONING.RELOAD_TEXTE");
          const RELOAD_BT = 'Recharger';  // this.translate.instant("VERSIONING.RELOAD_BT");
  
          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: RELOAD_TITRE,
            subHeader: data.version,
            message: RELOAD_TEXTE,
            buttons: [{
              text: RELOAD_BT,
              handler: () => {
                console.log('Confirm Ok');
                window.location.reload();
              }
            }]
          });    
          await alert.present();
        }
      })
    }


  /////////////////// GESTION DES LANGUES  /////////////////
  changeLangue(lang) {
    this.LANG = lang;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    console.log(this.LANG);
  }
  returnLangForUrl() {
    if(this.LANG != 'fr')
      return this.LANG;
    return '';
  }

  ///////////////////// FIREBASE ///////////////////////////
  async removeDocument(path: string) {
    await this.storage.storage.ref(path).delete();
  }

  ///////////////////// FONCTIONS  UTILES //////////////////

  generateUniqueFirestoreId(){
    // Alphanumeric characters
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
  }
 

  cleanHtml(str) {
    if (str) {
      return str.replace(/<[^>]*>/g, '');
    } else {
      return '';
    }
  }

  isValid(toTest, value) {

    if(value == undefined || value == '') return true;

    let regex: RegExp = null;
    if (toTest == 'telephone')
      regex = (/0[1-9]((([0-9]{2}){4})|(( ?[0-9]{2}){4})|((\.?[0-9]{2}){4})|((-?[0-9]{2}){4}))$/);
    else
      regex = (/[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)$/);
    return regex.test(value);
  }

  tronquer(str:string, max = 20, suffixe = '...', mode='mots') {
    if(mode =='mots'){
      // mode mots
      const taille = str.split(' ').length;
      if(taille>max){
        return str.split(' ').splice(0,max).join(' ')+suffixe;
      }else{
        return str;
      }
    }else{
      // mode lettres 
      if (str.length > max - suffixe.length) {
        str = str.slice(0, max - suffixe.length) + suffixe;
      }
      return str;
    }
  }

  slugify(str) {
    if (str) {
      str = str.replace(/^\s+|\s+$/g, '');

      // Make the string lowercase
      str = str.toLowerCase();

      // Remove accents, swap ñ for n, etc
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      // Remove invalid chars
      str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-');
    }
    return str;
  }

  openUrl(url,download=false){
    if (this.platform.is('cordova')) {
      this.iab.create(url,'_system');
    }else{
      if(download){
        window.open(url, 'Download');  // A FAIRE
      }else{
        window.open(url, '_system');
      }
    }
  }
  

validateTel(telephone) {
  var re = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
  return re.test(String(telephone));
} 

async toast(str){
  const toast = await this.toastCtrl.create({
    mode: 'ios',
    duration: 2500,
    message: str,
    position : 'middle',
    color: 'dark',
  });
  await toast.present();
}
async errorAlert(title: string, reason: string, handler) {
  const alert = await this.alert.create({
    header: title,
    message: reason,
    buttons: [{
      text: 'D\'accord',
      role: 'close',
      handler
    }]
  });
  await alert.present();
}




  ///////////////////// SEO //////////////////

  urlPost(article) {
    
    let titre = article['titre_'+this.LANG];
    // console.log('urlPost project',article.type);

    if (article.type == 'event' || article.type == 'article' || article.type == 'project') {
      if (article.meta_url) titre = article.meta_url;

      if (article.type == 'event') {
        // return '/'+this.returnLangForUrl()+'/event/' + this.slugify(titre) + '-' + article.eventID;
        return '/event/' + this.slugify(titre) + '-' + article.eventID;
      }
      if (article.type == 'article') {
        return '/'+this.returnLangForUrl()+'/article/' + this.slugify(titre) + '-' + article.articleID;
      }
      if (article.type == 'project') {
        // titre = article.titre_fr;
        //return '/'+this.returnLangForUrl()+'/palace/' + this.slugify(titre) + '-' + article.projectID;
        // return '/app/tabs/opportunities/opportunitie-details/' + article.projectID;
        return '/opportunitie/' + article.projectID;
      }
    } else {
      titre = article.nom;
      if (article.meta_url) titre = article.meta_url;
      //return '/'+this.returnLangForUrl()+'/' + this.slugify(titre) + '-' + article.reseauID;
      return '/'+this.returnLangForUrl()+'/' + this.slugify(titre) + '-' + article.reseauID;
    }

  }

  changeBreadcrum(article, breadcrum, niveau) {
    // console.log('changeBreadcrum1',breadcrum);
    const url = this.urlPost(article);
    let titre = article.titre;
    if (article.meta_title) titre = article.meta_title;
    breadcrum[niveau] = { url, name: titre };
    this.title.setTitle(titre);
    // console.log('changeBreadcrum1',breadcrum);
    return breadcrum;
  }

  ///// SEO GA /////
   eventga(categorie,action,libelle){
    console.log('eventga('+ categorie +','+ action + ','+ libelle +')');
    console.log('dataLayer:',window['dataLayer']);
    // window['dataLayer'].push({"event":"evenement_ga","categorie":categorie,"action":action,"libelle":libelle});
  }
  eventgaPagevue(type_page) {
    console.log('eventgaPagevue(' + type_page + ')');
    let typeProd = 'developpement';
    if(environment.production){
      typeProd = 'production';
    }
    // window['dataLayer'].push({ "event": "page_vue", "type_page": type_page, "environnement": typeProd });
  }
  ///// SEO NATURAL /////
  seo(article: any, changeSeo = true, changeUrl = true) {
    console.log('seo', changeSeo ,changeUrl);
    console.log(article);
    // SEO TITLE **********************************
    if (changeSeo) {
      let title = article.titre;
      if (article.meta_title) { title = article.meta_title; };
      this.title.setTitle(title);
      // SEO TAGS
      //description

    console.log(title);
      let desc = this.cleanHtml(article.description);
      if (desc.length > 155) desc = desc.slice(0, 155);
      if (article.meta_description) { desc = article.meta_description.slice(0, 155); }
      this.meta.updateTag({ name: 'description', content: desc });

      if(article.extra != 'non') {
        //defaults
        this.meta.updateTag({ name: 'robots', content: 'index' });
        this.meta.updateTag({ name: 'canonical', content: this.urlPost(article) });

        // métas : boucle sur les metas additionnels
        if (article.metas) {
          article.metas.forEach(element => {
            let obj = { [element.type + '']: element.name, content: element.content };
            // console.log("meta : ", obj);
            this.meta.updateTag(obj);
          });
        }
      }
      else {
        this.meta.updateTag({ name: 'canonical', content: article.canonical });
      }

    }
    if (changeUrl) {
      // change URL
      let url = this.urlPost(article);
      window.history.pushState('', article.titre, url);
    }
    // FIN SEO +++++++++++++++++++++++**************

  }

/*
///////////////////////// MODALS  ///////////////////////
  async openCGU(to:string) {
    const modal = await this.modalCtrl.create({
      component: CguPage,
      componentProps: {
        to: to,
      }
    });
    await modal.present();
  }
  async openMentions(to: 'mentions' | 'pol' | 'ml') {
    const modal = await this.modalCtrl.create({
      component: MentionsPage,
      componentProps: {
        to: to,
      }
    });
    await modal.present();
  }
 */

  ///////////////////////// CORDOVA PARTAGE SMS etc... ////////////////////////
  
  /*share(projet) {
    this.socialSharing.share(
      `Partage du projet ${projet.titre} \n${environment.url}project-detail/${projet.projectID}`,
      'Regarde ce projet',
    );
  }*/


  sms(qui){
		console.log('sms()',qui);

		const options = {
			replaceLineBreals: false,
			android: {
				intent: 'INTENT' 
			}
	  }
		this.lesms.send(qui.phone, 'Bonjour, ', options);
		//this.lesms.send(qui.phone, 'Mon TCF '+qui.pseudo+',');
  }

	call(qui){
		console.log('call()',qui);
		this.callNumber.callNumber(qui.phone, true)
		.then(res => console.log('Launched dialer!', res))
		.catch(err => console.log('Error launching dialer', err));
  }

	mail(qui){
		console.log('mail()',qui);
		window.location.href = 'mailto:'+qui.email+'?body=Bonjour ' +qui.pseudo+',&subject=Bonjour '+ qui.pseudo;	
  }

	/*async locate(qui) {
    console.log('goCarto()',qui);
    const modalCarto = await this.modalCtrl.create({
      component: CarteLocalisationPage,
      componentProps: { name: qui.pseudo, ou: qui }
    });
    modalCarto.present();
  }*/

  /*
  whatsapp(qui){
    console.log('whatsapp()',qui);
    this.socialSharing.shareViaWhatsApp( 'Bonjour' +qui.pseudo).then(() => {
      // Success!
    }).catch(() => {
      // Error!
    });
    // let uri = 'whatsapp//send?phone='+ qui.phone;
    // window.open(uri, '_system');
    
  }
  */
	iti(qui){

		console.log('iti :: ',qui);
		let uri = 'maps://?ll=' + qui.adresse ;
		if (this.platform.is('ios'))
		{  
			//uri = 'maps://?ll=' + qui.adresse + '&q=' + qui.adresse.coord;
				uri = 'maps://?ll=' + qui.adresse + '&q=' + qui.coord._lat + ',' + qui.coord._long;
				
			} else {
				uri = 'geo:0,0?q=' + qui.adresse + '(' + qui.coord._lat + ','+ qui.coord._long ;
			//uri = 'geo:0,0?q=' + qui.adresse;
		}
		console.log('uri :', uri);
		window.open(uri, '_system');
		//window.location.href=
  }

  

}
